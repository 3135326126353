body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapouter {
  position: relative;
  text-align: right;
  height: 400px;
  width: 400px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 400px;
  width: 400px;
}

.frm {
  margin-top: 5%;
  margin-bottom: 2%;
}

.frm-row {
  margin-bottom: 10px;
}

.vni-map {
  margin-top: 2%;
}

.p-span {
  font-size: 14px;
  color: #a6a6a6;
  margin-top: 20px;
}

.bgMission {
  background: url(/public/images/VMV_BG-Photo.jpg) no-repeat center center;
  background-size: cover;
  background-color: #49b544;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 130px;
}

.card-img-top {
  background-color: #eeeded;
  object-fit: contain;
  height: 15vw;
  max-height: 100%;
  padding: 10px 0px;
}

.AnyClassForContainer {
  position: fixed;
  right: -500px;
  bottom: 150px;
  transition: right 0.5s;
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  z-index: 2;
  outline: none;
}

.AnyClassForTransition {
  right: 10px;
}

.border {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

/* .carousel-indicators li {
  padding-top: 25px;
  background: transparent;
  border-radius: 100%;
} */

.pdf-style {
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
}
