/* General styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
  }
  
  .container-privacy {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .bg-privacy {
    background: url(./images/VMV_BG-Photo.jpg) no-repeat center center;
    background-size: cover;
    background-color: #49b544;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 130px;
  }

  .header-title {
    text-align: right;
    font-size: medium;
    font-weight: 900;
    color: black;
  }

  .green-bar {
    width: 20%;
    height: 5px;
    background-color: #98e1b2;
    margin-bottom: 20px;
    float: right;
    padding-bottom: 10px;
  }
  
  h1, h2, h3 {
    color: #333;
    margin-top: 1em;
    font-weight: 200;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
  }
  
  h2 {
    font-size: 1.75rem;
    border-top: 2px solid #ddd;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500    ;
  }
  
  h3 {
    font-size: 1.5rem;
  }
  
  p {
    font-size: 1rem;
    color: #555;
    margin: 1em 0;
  }
  
  ul {
    margin: 1em 0;
    padding-left: 50px;
  }
  
  ul li {
    margin-bottom: 0.5em;
    color: #444;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Specific styling */
  em {
    font-style: italic;
    color: #777;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.75rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    p, ul li {
      font-size: 0.9rem;
    }
  }
  